@tailwind base;
@tailwind components;
@tailwind utilities;

/* react-slick */
.slick-track {
  margin: 0 !important;
}

.slick-list {
  width: calc(100% + 24px);
}

.item {
  margin-right: 24px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 10ms;
}

.slick-dots li {
  width: 8px !important;
  height: 8px !important;
  margin: 0 10px 0 0 !important;
}

.slick-slide div {
  display: flex;
  justify-content: center;
}

.collabo-item .slick-slide div {
  justify-content: flex-start;
}

@layer base {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Collabo Apply button shadow */
  .shadow-multi {
    box-shadow:
      0px 2px 5px 0px rgba(0, 0, 0, 0.1),
      0px 10px 10px 0px rgba(0, 0, 0, 0.09),
      0px 22px 13px 0px rgba(0, 0, 0, 0.05),
      0px 38px 15px 0px rgba(0, 0, 0, 0.01),
      0px 60px 17px 0px rgba(0, 0, 0, 0);
  }
}

p[data-subheading='true'] {
  font-size: 18px;
  line-height: 140%;
  font-weight: 700;
  /* color: #303030; */

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

p[data-subheading='true']:before {
  display: block;
  content: '';
  width: 3px;
  height: 25px;
  background-color: #616161;
}

.editor-content img {
  display: unset;
}

.white-dth .dth {
  background: #fff;
}

.tiptap .is-editor-empty:first-child::before {
  color: #8b8b8b;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@keyframes entrance {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply the entrance animation to the component */
.animate-entrance {
  animation: entrance 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

#dashboard-pie {
  overflow: visible;
}

#category-chart .recharts-cartesian-axis-line,
#category-chart .recharts-cartesian-axis-tick-line {
  display: none;
}
.bn-container {
  --bn-colors-selected-background: #3366b6 !important;
}

.faq-list__item p {
  margin: 0 !important;
}

#character-slick .slick-list {
  padding-left: 8px;
}
#character-slick .slick-slide div {
  justify-content: flex-start;
}
#edit-info,
#admin-sidebar__content,
#client-chat,
#client-chat__desc {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#edit-info::-webkit-scrollbar,
#admin-sidebar__content::-webkit-scrollbar,
#client-chat::-webkit-scrollbar,
#client-chat__desc::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

/* Other browsers */
#creator-projects::-webkit-scrollbar {
  width: 4px;
}

#creator-projects::-webkit-scrollbar-track {
  background: transparent;
}

#creator-projects::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 4px;
}

.customRadio + label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #bdbdbd;
  transform: translateY(-50%);
}

.customRadio:checked + label::before {
  border-color: #3366b6;
}

.customRadio:checked + label::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 5px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3366b6;
  transform: translateY(-50%);
}

.trend .recharts-wrapper {
  transform: translateX(-28px);
}
.SortableHelper {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: rgb(244 244 245);
  border-spacing: 0;
}

.partners-layer {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 3;
}

.partners-layer.right {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 3;
}

@media screen and (max-width: 1440px) {
  .ProseMirror.bn-editor {
    padding: 0;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1700px) {
  .admin-collabo__layout {
    padding-right: 220px;
  }
}

@media screen and (max-width: 1024px) {
  #character-slick .slick-list {
    padding-left: 0;
    max-width: 100%;
  }

  #character-slick .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }
}

.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #3366b6;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
